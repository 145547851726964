<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl fluid>
            <!-- edit user data -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-form id="form_user_data_update" ref="form" v-model="form" lazy-validation>
                        <v-card id="form_card">
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>{{ userIcon }}</v-icon> Podatki o uporabniku <span class="caption" v-if="name !== null">({{ name }})</span></span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('user-data')">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>

                                <v-text-field
                                    v-model="name"  
                                    label="Ime in priimek"
                                    required
                                    :rules="name_rules"
                                    append-icon="mdi-account"
                                ></v-text-field>

                                <v-text-field
                                    v-model="email"
                                    label="E-mail"
                                    required
                                    :rules="email_rules"
                                    append-icon="mdi-email"
                                ></v-text-field>

                                <v-text-field
                                    v-model="last_seen"
                                    label="Čas zadnje prijave"
                                    required
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                ></v-text-field>
                                
                            </v-card-text>
                            <v-divider v-show="card1 && !admin"></v-divider>
                            <v-card-text v-show="card1 && !admin" v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn v-if="!admin" :disabled="loading" @click="submitForm()" color="success" block outlined style="margin-bottom:10px;"><v-icon left>mdi-arrow-right</v-icon>Posodobi podatke</v-btn>
                                <v-btn v-if="!admin" :disabled="loading" @click="clearForm()" color="warning" block outlined style="margin-bottom:10px;"><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                                <v-btn v-if="!admin" :disabled="loading" @click="deleteUser()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-close</v-icon>Odstrani uporabnika</v-btn>
                            </v-card-text>
                            <v-card-actions v-show="card1 && !admin" v-else>
                                <v-btn v-if="!admin" :disabled="loading" @click="submitForm()" color="success" text><v-icon left>mdi-arrow-right</v-icon>Posodobi podatke</v-btn>
                                <v-btn v-if="!admin" :disabled="loading" @click="clearForm()" color="warning" text><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                                <v-btn v-if="!admin" :disabled="loading" @click="deleteUser()" color="error" text><v-icon left>mdi-close</v-icon>Odstrani uporabnika</v-btn>
                            </v-card-actions>
                            <div style="min-height:5px;"></div>
                        </v-card>
                    </v-form>
                </v-flex>
            </v-layout>
            <!-- obrazec za zamenjavo uporabniškega gesla -->
            <update-user-password :uuid="uuid" v-if="!admin"></update-user-password>

            <v-layout row wrap align-center justify-center fill-height v-if="!admin">
                <v-flex xs12 sm12 md12 lg12 x12>
                    <v-card>
                        <v-card-title>
                                <span class="subtitle-1"><v-icon left>mdi-file-document-box-check-outline</v-icon> Nova dovolilnica za uporabnika </span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('dovolilnice')">
                                    <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card2"></v-divider>
                        <v-card-text v-show="card2" v-if="$vuetify.breakpoint.xsOnly">
                            <v-layout row wrap align-center justify-center fill height v-for="(item, index) in permitItems" :key="index">
                                <v-flex xs12>
                                    <span class="subtitle-1">
                                        <v-icon left>{{ item.icon }}</v-icon> 
                                        {{ item.title }}
                                    </span>   
                                </v-flex>
                                <v-flex xs12>
                                    <v-btn dark color="secondary" small @click="goToRoute(item.routeName)" block outlined>
                                        <v-icon small left>mdi-pencil</v-icon>
                                        Nova dovolilnica
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                        <v-card-text v-show="card2" v-else>
                            <v-list>
                                <v-list-item
                                    v-for="item in permitItems"
                                    :key="item.title"
                                    @click="goToRoute(item.routeName)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="">{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                
                                        <v-list-item-content>
                                            <v-list-item-title class="body-2" v-text="item.title"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-btn dark color="secondary" small>
                                                <v-icon small left>mdi-pencil</v-icon>
                                                Nova dovolilnica
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <div style="min-height:12px;"></div>
            <applications-data-table
            :settings="applicationsDataTableSettings"
            :queryParameters="applicationsQueryParameters"
            :boxedLayout="false"
            v-if="renderDataTables && !admin"
            ></applications-data-table> 

            <br>
            <permits-data-table
            :settings="permitsDataTableSettings"
            :queryParameters="permitsQueryParameters"
            :boxedLayout="false"
            v-if="renderDataTables && !admin"
            ></permits-data-table>
        </v-container>

        <data-table-toggle-columns
        :dataTableId="applicationsDataTableSettings.id"
        :columns="applicationsDataTableSettings.headers"
        :dataTableColumnsMenuId="applicationsDataTableSettings.eventBus.toggleColumnsId"

        ></data-table-toggle-columns>

        <data-table-toggle-columns
        :dataTableId="permitsDataTableSettings.id"
        :columns="permitsDataTableSettings.headers"
        :dataTableColumnsMenuId="permitsDataTableSettings.eventBus.toggleColumnsId"

        ></data-table-toggle-columns>

        <v-dialog v-model="dialog" persistent max-width="490">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-icon text>mdi-delete</v-icon>
                    {{ dialog_text }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click.native="dialogCancel()">Prekliči</v-btn>
                    <v-btn text :loading="loading" color="success" @click.native="dialogConfirm()">Nadaljuj</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
import ApplicationsDataTable from '@/components/ApplicationsDataTable.vue'
import PermitsDataTable from '@/components/PermitsDataTable.vue'
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const UpdateUserPassword = () => import('@/components/admin/UpdateUserPassword.vue')

export default {
    components: {
        ApplicationsDataTable,
        PermitsDataTable,
        DataTableToggleColumns,
        FormValidationMessages,
        UpdateUserPassword
    },

    data:() => ({
        id: null,
        uuid: null,
        name: null,
        email: null,
        last_seen: '',

        form: false,
        card1: true,
        card2: true,
        admin: false,

        residenceTypes: [],

        user_type_rules: [v => !!v || 'Polje tip uporabnika je obvezno'],
        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        address_rules: [v => !!v || 'Polje naslov prebivališča je obvezno'],
        town_rules: [v => !!v || 'Polje kraj je obvezno'],
        postal_code_rules: [v => !!v || 'Polje poštna številka je obvezno'],
        residence_type_rules: [v => !!v || 'Polje tip prebivališča je obvezno'],
        telephone_number_rules: [v => !!v || 'Polje telefonska številka je obvezno'],
        email_rules: [
            v => !!v || 'Polje e-mail je obvezno',
            v => /.+@.+\..+/.test(v) || 'Vnesen elektronski naslov ni pravilen'
        ],
        emso_rules: [v => !!v || 'Polje emso je obvezno'],
        entity_rules: [v => !!v || 'Polje tip osebe je obvezno'],

        applicationsDataTableSettings: {
            id: 'dtUserApplications',
            title: 'Seznam vlog',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", align: "left", visibility: true},
                { text: "Uporabnik", value: "name", visibility: true},
                //{ text: "Ime in priimek", value: "user.name", visibility: true},
                /*{ text: "Naslov", value: "user.address", visibility: true},
                { text: "Poštna št.", value: "user.postal_code", visibility: true},
                { text: "Kraj", value: "user.town", visibility: true},*/
                { text: "Datum vpisa", value: "created_at", visibility: true},
                { text: "Tip vloge", value: "permit_type", visibility: true},
                { text: "Status", value: "application_status", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: '',
            editItem: {
                route: "admin_applications_edit",
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_APPLICATION',
                replaceStrWithColumnValue: 'id',
                confirmationText : "Sta prepričani da želite odstraniti vlogo številka #{str_to_replace}?",
                dialogConfirmSuccessText: 'Vloga s številko #{str_to_replace} je bila uspešno odstranjena.',
                dialogConfirmFailText: 'Pri odstranjevanju vloge s številko #{str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_user_applications',
                totalItems : {
                    event: ''
                },
                search: 'dt-user-applications-search',
                toggleColumnsId: 'toggle-user-applications-list-columns'
            }


        },

        permitsDataTableSettings: {
            id: 'dtUserPermits',
            title: 'Seznam dovolilnic',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", align: "left", visibility: true},
                { text: "Uporabnik", value: "name", visibility: true},
                //{ text: "Imetnik", value: "name", visibility: true},
                { text: "Naslov", value: "address", visibility: true},
                { text: "Poštna št.", value: "postal_code", visibility: true},
                { text: "Kraj", value: "town", visibility: true},
                //{ text: "Datum vpisa", value: "created_at", visibility: true},
                { text: "Tip uporabnika", value: "user_type", visibility: true},
                { text: "Tip dovolilnice", value: "permit_type", visibility: true},
                { text: "Status", value: "permit_status", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: '',
            editItem: {
                route: 'admin_permits_edit',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_PERMIT',
                replaceStrWithColumnValue: 'id',
                confirmationText : "Sta prepričani da želite odstraniti dovolilnico številka #{str_to_replace}? Odstranjene bodo tudi vse dovolilnice pripadajoče vloge.",
                dialogConfirmSuccessText: 'Dovolilnica s številko #{str_to_replace} je bila uspešno odstranjena.',
                dialogConfirmFailText: 'Pri odstranjevanju vloge s številko #{str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_user_permits',
                totalItems : {
                    event: ''
                },
                search: 'dt-user-permits-search',
                toggleColumnsId: 'toggle-user-permits-list-columns'
            }


        },

        permitItems: [
            {
                title: 'Parkirna dovolilnica (Blejska kartica)',
                mobile_title: 'Parkirna dovolilnica',
                icon: 'mdi-parking',
                routeName: 'admin_user_parking_permit'
            },
            {
                title: 'Dovolilnica za dostop na Blejsko promenado',
                mobile_title: 'Dovolilnica za dostop na promenado',
                icon: 'mdi-city-variant-outline',
                routeName: 'admin_user_promenada_permit'
            }
        ],

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        dialog:false,
        dialog_text: null,
        loading: false
        
    }),

    computed: {
        userIcon() {
            if(!this.admin) {
                return 'mdi-account-circle'
            }

            return 'mdi-incognito'
        },

        applicationsQueryParameters() {
            return {
            }
        },

        permitsQueryParameters() {
            return {
            }
        },

        renderDataTables() {
            if(this.uuid != null && this.uuid != null && this.uuid !== undefined) {
                return true
            }

            return false
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        }
    },

    methods: {
        toggleCardContent(card) {
            if(card === 'user-data') {
                this.card1 = !this.card1
            }

            if(card === 'dovolilnice') {
                this.card2 = !this.card2
            }
        },

        goToRoute(routeName) {
            this.$router.push({
                name: routeName,
                params: {
                    id: this.$route.params.id
                }
            })
        },

        getUserData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
                this.$store.dispatch('EDIT_USER', this.uuid)
                .then(response => {
                    //window.console.log(response.data)
                    vm.id = response.data.data.id
                    vm.name = response.data.data.name
                    vm.email = response.data.data.email
                    vm.last_seen = response.data.data.last_seen
                    vm.admin = (response.data.data.role_type.type == 1) ? true : false

                })
                .catch(error => {
                    window.console.error(error)
                })
                .finally(() =>{
                    vm.$store.commit('SET_PROGRESS', false)
                })  
        },

        submitForm() {
            this.backendErrorMessages = []
            this.backendValidationMessages = []
            this.backendMessages = []
            var vm = this
            if(this.$refs.form.validate()) {
                vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev podatkov o uporabniku je v teku...')
                vm.$store.commit('SET_LOADER', true)
                vm.loading = true
                vm.$store.dispatch('USER_PROFILE_UPDATE', {
                    id: vm.id,
                    uuid: vm.uuid,
                    name: vm.name,
                    email: vm.email
                })
                .then(response => {
                    setTimeout(() => {
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                        vm.$store.commit('SET_SNACKBAR_TEXT', "Podatki uporabnika so bili uspešno posodobljeni.")
                        vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                        vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    }, 700)
                })
                .catch(error => {
                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)

                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_card', {
                                duration: 500,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    } else {
                        setTimeout(() => {
                            vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                            vm.$store.commit('SET_SNACKBAR_TEXT', "Pri posodabljanju podatkov o uporabniku je prišlo do napake. Poskusite ponovno.")
                            vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                            vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                            vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                        }, 700)

                        vm.backendErrorMessages.push('Ups... pri posodobitvi podatkov uporabnika je prišlo do napake. Poskusite ponovno.')
                        vm.$vuetify.goTo('#form_card', {
                            duration: 500,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    }, 600)
                    vm.loading = false
                })
            } 
            else {
                window.console.log("Validation ERROR");
            }
            
        },

        clearForm() {
            var vm = this
            this.loading = true
            this.backendErrorMessages = []
            this.backendValidationMessages = []
            this.backendMessages = []

            this.$refs.form.reset();
            this.getUserData();
            this.$refs.form.resetValidation();
            setTimeout(() => {
                vm.loading = false;
            }, 700)
        },

        deleteUser() {
            this.dialog_text = "Ste prepričani, da želite odstraniti uporabnika " + this.name + "? Odstranjene bodo tudi vse pripadajoče vloge in dovolilnice tega uporabniškega računa."
            this.dialog = true;
        },

        dialogConfirm() {
            var vm = this
            vm.dialog = false
            this.$store.commit('SET_LOADER_TEXT', 'Brisanje uporabniškega računa je v teku...')
            this.$store.commit('SET_LOADER', true)
            this.$store.dispatch('DELETE_USER', this.uuid)
            .then(response => {
                vm.$store.commit('SET_LOADER', false)
                vm.$router.push({
                    name: 'admin_users'
                })

                setTimeout(() => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Uporabnik " +vm.name + " je bil uspešno odstranjen.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                }, 900)
            })
            .catch(error => {
                window.console.error("### ERROR DELETE_USER ###")
                window.console.error(error)

                this.$store.commit('SET_LOADER', false)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri brisanju uporabnika " + vm.name + " je prišlo do napake. Poskusite ponovno.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
        },

        dialogCancel() {
            this.dialog = false
        }
    },

    created() {
       this.$store.dispatch('ROUTE_NAME', this.$route.name)
    },

    mounted() {
        this.uuid = this.$route.params.id
        this.applicationsDataTableSettings.endpoint = 'v1/admin/users/' + this.uuid +  '/applications'
        this.permitsDataTableSettings.endpoint = 'v1/admin/users/' + this.uuid +  '/permits'
        this.residenceTypes = this.$store.getters.residenceTypes
        this.getUserData()
    }
}

</script>

<style scope>

</style>